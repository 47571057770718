import * as React from "react";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const ErrorPage = (props) => {


    return (
        <>
            <div className="contact-page">
                <section className="errorpage contact-book">
                    <Container>
                        <Row className="d-flex justify-content-center bd-highlight mb-3 text-center">
                            <Col md="9">
                                <h1>We're sorry, the property you were looking for cannot be found.</h1>
                                <p>This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
                                <div className="banner-ctas">
                                    <a link="/property/for-sale/in-dubai/" className="btn">Find a Property <i class="icon-right"></i></a>
                                    <a link="/contact/" className="btn btn-secondary">Contact Us<i class="icon-right"></i></a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
};
export default ErrorPage;

